import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ValueGraph from 'components/tools/ValueGraph';

const AgentDailyStats = ({ isMobile, mobileStatsOpen }) => {
  const userData = useSelector((state) => state.user);

  const newCallsValue = userData?.dailyNewCalls || 0;
  const newCallsGoal = userData?.newCallGoals || 0;
  const callsValue = userData?.dailyCalls || 0;
  const callGoals = userData?.callGoals || 60;
  const emailsValue = userData?.dailyEmails || 0;
  const emailGoals = userData?.emailGoals || 30;
  // const meetingsValue = userData?.meetings || 0;
  // const meetingsGoal = userData?.meetingsGoal || 5;
  const listEmailsValue = userData?.dailyListEmails || 0;
  const listEmailsGoal = userData?.dailyListEmailGoals || 30;
  const setupsValue = userData?.setups || 0;
  const setupsGoal = userData?.setupGoals || 10;
  const packetsValue = userData?.packets || 0;
  const packetsGoal = userData?.packetGoals || 0;
  const marginValue = userData?.margin || 0;
  const marginGoal = userData?.marginGoals || 0;
  const quotesValue = userData?.quotes || 0;
  const quotesGoal = userData?.quoteGoals || 0;
  const loadsValue = userData?.loadsBooked || 0;
  const loadsGoal = userData?.loadsBookedGoals || 0;

  return (
    <>
     {isMobile ? (
      mobileStatsOpen && (
        <Paper sx={{ marginBottom: 1, p: 1 }}>
          <Paper sx={{ marginBottom: 1, p: 1, width: "100%", }}>
            <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                <ValueGraph value={newCallsValue} valueGoal={newCallsGoal} label="New Calls" />
              </Box>
              <Box sx={{ width: '48%' }}>
                <ValueGraph value={callsValue} valueGoal={callGoals} label="Follow Up Calls" />
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
            <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                <ValueGraph value={emailsValue} valueGoal={emailGoals} label="Emails" />
              </Box>
              <Box sx={{ width: '48%' }}>
                <ValueGraph value={setupsValue} valueGoal={setupsGoal} label="Setups" />
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
            <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                {/* <ValueGraph value={meetingsValue} valueGoal={meetingsGoal} label="Meetings" /> */}
                <ValueGraph value={listEmailsValue} valueGoal={listEmailsGoal} label="List Emails" />
              </Box>
              <Box sx={{ width: '48%' }}>
                {/* <GoallessValueGraph value={packetsValue} valueGoal={packetsGoal} label="Packets" /> */}
                <ValueGraph value={packetsValue} valueGoal={packetsGoal} label="Packets" />
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
            <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
              <Box sx={{ width: '48%' }}>
                {/* <GoallessValueGraph value={loadsValue} valueGoal={loadsGoal} label="Loads Booked" /> */}
                <ValueGraph value={loadsValue} valueGoal={loadsGoal} label="Loads Booked" />
              </Box>
              <Box sx={{ width: '48%' }}>
                {/* <GoallessValueGraph value={quotesValue} valueGoal={quotesGoal} label="Quotes" /> */}
                <ValueGraph value={quotesValue} valueGoal={quotesGoal} label="Quotes" />
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
            <Box sx={{ width: '100%' }}>
              {/* <GoallessValueGraph value={marginValue} valueGoal={marginGoal} label="Margin" /> */}
              <ValueGraph value={marginValue} valueGoal={marginGoal} label="Margin" />
            </Box>
          </Paper>
        </Paper>
      )
     ) : (
      <Paper sx={{ marginBottom: 1, p: 1 }}>
        <Paper sx={{ marginBottom: 1, p: 1, width: "100%", }}>
          <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              <ValueGraph value={newCallsValue} valueGoal={newCallsGoal} label="New Calls" />
            </Box>
            <Box sx={{ width: '48%' }}>
              <ValueGraph value={callsValue} valueGoal={callGoals} label="Follow Up Calls" />
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
          <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              <ValueGraph value={emailsValue} valueGoal={emailGoals} label="Emails" />
            </Box>
            <Box sx={{ width: '48%' }}>
              <ValueGraph value={setupsValue} valueGoal={setupsGoal} label="Setups" />
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
          <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              {/* <ValueGraph value={meetingsValue} valueGoal={meetingsGoal} label="Meetings" /> */}
              <ValueGraph value={listEmailsValue} valueGoal={listEmailsGoal} label="List Emails" />
            </Box>
            <Box sx={{ width: '48%' }}>
              {/* <GoallessValueGraph value={packetsValue} label="Packets" /> */}
              <ValueGraph value={packetsValue} valueGoal={packetsGoal} label="Packets" />
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
          <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between' }}>
            <Box sx={{ width: '48%' }}>
              {/* <GoallessValueGraph value={loadsValue} label="Loads Booked" /> */}
              <ValueGraph value={loadsValue} valueGoal={loadsGoal} label="Loads Booked" />
            </Box>
            <Box sx={{ width: '48%' }}>
              {/* <GoallessValueGraph value={quotesValue} label="Quotes" /> */}
              <ValueGraph value={quotesValue} valueGoal={quotesGoal} label="Quotes" />
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
          <Box sx={{ width: '100%' }}>
            {/* <GoallessValueGraph value={marginValue} label="Margin" /> */}
            <ValueGraph value={marginValue} valueGoal={marginGoal} label="Margin"/>
          </Box>
        </Paper>
      </Paper>
     )}
    </>
  );
};

export default AgentDailyStats;



import React from "react";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import PublicOutlined from "@mui/icons-material/PublicOutlined";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import Groups2Outlined from "@mui/icons-material/Groups2Outlined";
import ReceiptLongOutlined from "@mui/icons-material/ReceiptLongOutlined";
import PointOfSaleOutlined from "@mui/icons-material/PointOfSaleOutlined";
import TodayOutlined from "@mui/icons-material/TodayOutlined";
import CalendarMonthOutlined from "@mui/icons-material/CalendarMonthOutlined";
import PieChartOutlined from "@mui/icons-material/PieChartOutlined";
import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import TrendingUpOutlined from "@mui/icons-material/TrendingUpOutlined";
import Inventory from "@mui/icons-material/Inventory";
import BarChart from "@mui/icons-material/BarChart";
import SupportAgent from "@mui/icons-material/SupportAgent";
import SpaceDashboard from "@mui/icons-material/SpaceDashboard";
import CoPresent from "@mui/icons-material/CoPresent";

//define link objects title and icon.
const dashboardLink = { text: "Dashboard", icon: <HomeOutlined /> };
const userNavTitle = { text: "User Navigation", icon: null };
const customerSupportLink = {
  text: "Customer Support",
  icon: <SupportAgent />,
};
const operationsLink = { text: "Operations", icon: <CoPresent /> };
const dispatchLink = { text: "Dispatch", icon: <PublicOutlined /> };
const dispatchBoardLink = {
  text: "Dispatch Board 2.0 (Beta)",
  icon: <SpaceDashboard />,
};
const archiveLink = { text: "Archive", icon: <Inventory /> };
const statsLink = { text: "Stats", icon: <BarChart /> };
const productsLink = { text: "Products", icon: <ShoppingCartOutlined /> };
const customersLink = { text: "Customers", icon: <Groups2Outlined /> };
const transactionsLink = {
  text: "Transactions",
  icon: <ReceiptLongOutlined />,
};
const geographyLink = { text: "Geography", icon: <PublicOutlined /> };
const salesTitle = { text: "Sales", icon: null };
const overviewLink = { text: "Overview", icon: <PointOfSaleOutlined /> };
const dailyLink = { text: "Daily", icon: <TodayOutlined /> };
const monthlyLink = { text: "Monthly", icon: <CalendarMonthOutlined /> };
const breakdownLink = { text: "Breakdown", icon: <PieChartOutlined /> };
const managementLink = { text: "Management", icon: null };
const adminLink = { text: "Admin", icon: <AdminPanelSettingsOutlined /> };
const performanceLink = { text: "Performance", icon: <TrendingUpOutlined /> };

//current roles - "customer", "agent", "dispatch", "manager", "admin", "superadmin" 05/22/23 - links should be in an array
export const AdminItems = [
  dashboardLink,
  userNavTitle,
  statsLink,
  customerSupportLink,
  operationsLink,
  dispatchLink,
  dispatchBoardLink,
  archiveLink,
  productsLink,
  customersLink,
  transactionsLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
  managementLink,
  adminLink,
  performanceLink,
];
export const AgentItems = [
  dashboardLink,
  userNavTitle,
  customersLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
];
export const OperationsItems = [
  dashboardLink,
  userNavTitle,
  customersLink,
  operationsLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
]
export const DispatchItems = [
  dashboardLink,
  userNavTitle,
  dispatchLink,
  dispatchBoardLink,
  archiveLink,
];
export const ManagerItems = [
  dashboardLink,
  userNavTitle,
  statsLink,
  customerSupportLink,
  operationsLink,
  dispatchLink,
  dispatchBoardLink,
];
export const CustomerItems = [dashboardLink, userNavTitle];

import React, { useState, /* useEffect */ } from 'react';
import { useFormik } from 'formik';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
// import { setupWebSocket } from '../helpers/WebSocketSetup';
import { useSendEmailDispatchMutation, useUpdateDispatchMutation } from "state/api";
import mongoose from 'mongoose';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useAddCardToBookedColumnMutation } from 'state/dispatchApi';


const CarrierSubmissionForm = ({ open, closeForm }) => {
  const theme = useTheme();
  const isConnected = useSelector((state) => state.webSocket.isConnected);
  // const [socket, setSocket] = useState(null);
  // const [columns, setColumns] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [socketError, setSocketError] = useState(false)
  const [socketErrorMessage, setSocketErrorMessage] = useState("");
  const [updateDispatch] = useUpdateDispatchMutation();
  const [updateNewDispatch] = useAddCardToBookedColumnMutation();
  const [sendEmailDispatch] = useSendEmailDispatchMutation();
  // const [formErrors, setFormErrors] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  //! BEFORE TESTING THIS FORM UNPLUG THE API SO WE DON't EMAIL DISPATCH
  /* useEffect(() => {
    const componentName = "Dispatch";
    const socket = setupWebSocket(process.env.REACT_APP_WEBSOCKET_URL, componentName);

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on('update', (updatedColumns) => {
      setColumns(updatedColumns);
    });

    socket.on('update-success', (message) => {
      console.log('Update successful:', message);
      setIsSubmitted(true);
      setSocketError(false);
      sendEmailDispatch();
    });

    socket.on('update-failed', (message) => {
      console.error('Update failed:', message);
      setIsSubmitted(false);
      setSocketError(true);
      setSocketErrorMessage(message);
    });

    setSocket(socket);
    return () => {
      socket.disconnect();
    };
  }, []); */

  const handleCarrierFormSubmission = async (values) => {
    const id = new mongoose.Types.ObjectId().toString();
    const title = values.proNumber + " | " + values.originCity + " - " + values.destinationCity + " (" + values.loadOwner + ")";
    const subtitle = '';
    const notes = '';
    const description = `
    Customer Email: ${values.customerEmail},
    Load Owner: ${values.loadOwner},
    Pro Number: ${values.proNumber},
    Are They Set Up: ${formik.values.areTheySetUp},
    MC Number: ${values.mcNumber},
    Dispatcher Name: ${values.dispatcherName},
    Dispatcher Phone: ${values.dispatcherPhone},
    Dispatcher Email: ${values.dispatcherEmail},
    Driver Names: ${values.driverNames},
    Driver Numbers: ${values.driverNumbers},
    Pickup Appointment: ${formik.values.pickupAppointment},
    Delivery Appointment: ${formik.values.deliveryAppointment},
    Pickup Time Zone: ${formik.values.pickupTimeZone},
    Reference Number: ${formik.values.referenceNumber},
    Instructions for Dispatch: ${values.specialInstructions}`;
    const color = theme.palette.primary[1000]
    // const columnId = 'column-1';
    // const column = columns[columnId];
    // const newItems = [...column.items, { id, title, subtitle, notes, description, color }];
    // const newColumn = { ...column, items: newItems };
    // const updatedColumns = { ...columns, [columnId]: newColumn };
    // setIsSubmitted(true)
    // setColumns(updatedColumns);
    const newCard = {
      id,
      title,
      subtitle,
      notes,
      description,
      color,
      archivedBy: id
    }
    try {
      // socket.emit('update', updatedColumns);
      await updateNewDispatch(newCard);
      await updateDispatch(newCard)
        .then(() => {
          sendEmailDispatch();
          setIsSubmitted(true)
          enqueueSnackbar("Dispatch card added");
          closeForm();
        });
    } catch (error) {
      setIsSubmitted(false);
      setSocketError(true);
      setSocketErrorMessage(error);
      console.error('Error updating columns:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      originCity: '',
      destinationCity: '',
      customerEmail: '',
      loadOwner: '',
      proNumber: '',
      mcNumber: '',
      dispatcherName: '',
      dispatcherPhone: '',
      dispatcherEmail: '',
      driverNames: '',
      driverNumbers: '',
      specialInstructions: '',
      areTheySetUp: '',
      pickupAppointment: '',
      deliveryAppointment: '',
      pickupTimeZone: '',
      referenceNumber: '',
    },
    validationSchema: yup.object({
      originCity: yup.string().required('Origin city is required'),
      destinationCity: yup.string().required('Destination city is required'),
      customerEmail: yup.string().email('Invalid email address').required('Customer email required'),
      loadOwner: yup.string().required('Load owner is required'),
      proNumber: yup.number().required('Pro number is required'),
      mcNumber: yup.number().required('MC number is required'),
      dispatcherName: yup.string().required('Dispatcher name is required'),
      dispatcherPhone: yup.string().required('Dispatcher phone is required'),
      dispatcherEmail: yup.string().email('Invalied email address').required('Dispatcher email required'),
      driverNames: yup.string().required('Driver name(s) required'),
      driverNumbers: yup.string().required('Driver number(s) required'),
      specialInstructions: yup.string().required('Instructions required'),
      areTheySetUp: yup.string().required('Set up status required'),
      pickupAppointment: yup.string().required('Pickup time required'),
      deliveryAppointment: yup.string().required('Delivery time required'),
      pickupTimeZone: yup.string().required('Pickup time zone required'),
      referenceNumber: yup.string().required('Reference number required')
    }),
    onSubmit: values => {
      handleCarrierFormSubmission(values)
    },
  });

  return (
    <Dialog open={open} onClose={closeForm} fullWidth maxWidth="xl">
      <DialogContent>
        <Container
          component="main"
          maxWidth="xl"
          padding="40px"
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.primary[900],
            padding: '40px',
            margin: '0 auto',
          }}
        >
          <Typography variant="h4" component="h1" mb={3}>
            Carrier Submission Form
          </Typography>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", marginLeft: "60px" }}>
            <Typography variant="h6" component="h2" gutterBottom sx={{ paddingBottom: 1 }}>
              WebSocket Status:
              {isConnected ?
                <Typography sx={{ color: "#00deeb" }}>Connected</Typography>
                :
                <Typography sx={{ color: "#FF0000" }}>Disconnected</Typography>
              }
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="originCity"
                  label="Origin City"
                  name="originCity"
                  autoFocus
                  value={formik.values.originCity}
                  onChange={formik.handleChange}
                />
                {formik.touched.originCity && formik.errors.originCity && (
                  <Alert severity="error">{formik.errors.originCity}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="destinationCity"
                  label="Destination City"
                  name="destinationCity"
                  autoFocus
                  value={formik.values.destinationCity}
                  onChange={formik.handleChange}
                />
                {formik.touched.destinationCity && formik.errors.destinationCity && (
                  <Alert severity="error">{formik.errors.destinationCity}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="customerEmail"
                  label="Customer Email"
                  name="customerEmail"
                  autoComplete="email"
                  autoFocus
                  value={formik.values.customerEmail}
                  onChange={formik.handleChange}
                />
                {formik.touched.customerEmail && formik.errors.customerEmail && (
                  <Alert severity="error">{formik.errors.customerEmail}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="loadOwner"
                  label="Load Owner"
                  name="loadOwner"
                  autoFocus
                  value={formik.values.loadOwner}
                  onChange={formik.handleChange}
                />
                {formik.touched.loadOwner && formik.errors.loadOwner && (
                  <Alert severity="error">{formik.errors.loadOwner}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="areTheySetUp"
                  label="Are They Set Up?"
                  name="areTheySetUp"
                  autoFocus
                  value={formik.values.areTheySetUp}
                  onChange={formik.handleChange}
                  select
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Packet sent">Packet sent</MenuItem>
                  <MenuItem value="Missing COI">Missing COI</MenuItem>
                </TextField>
                {formik.touched.areTheySetUp && formik.errors.areTheySetUp && (
                  <Alert severity="error">{formik.errors.areTheySetUp}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="proNumber"
                  label="PRO Number"
                  name="proNumber"
                  autoFocus
                  value={formik.values.proNumber}
                  onChange={formik.handleChange}
                />
                {formik.touched.proNumber && formik.errors.proNumber && (
                  <Alert severity="error">{formik.errors.proNumber}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="mcNumber"
                  label="MC Number"
                  name="mcNumber"
                  autoFocus
                  value={formik.values.mcNumber}
                  onChange={formik.handleChange}
                />
                {formik.touched.mcNumber && formik.errors.mcNumber && (
                  <Alert severity="error">{formik.errors.mcNumber}</Alert>
                )}
              </Grid>
              {/* <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="carrierRate"
                    label="Carrier Rate"
                    name="carrierRate"
                    autoFocus
                    value={formik.values.carrierRate}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.carrierRate && formik.errors.carrierRate && (
                  <Alert severity="error">{formik.errors.carrierRate}</Alert>
                )}
                </Grid> */}
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dispatcherName"
                  label="Dispatcher Name"
                  name="dispatcherName"
                  autoFocus
                  value={formik.values.dispatcherName}
                  onChange={formik.handleChange}
                />
                {formik.touched.dispatcherName && formik.errors.dispatcherName && (
                  <Alert severity="error">{formik.errors.dispatcherName}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dispatcherPhone"
                  label="Dispatcher Phone"
                  name="dispatcherPhone"
                  autoFocus
                  value={formik.values.dispatcherPhone}
                  onChange={formik.handleChange}
                />
                {formik.touched.dispatcherPhone && formik.errors.dispatcherPhone && (
                  <Alert severity="error">{formik.errors.dispatcherPhone}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dispatcherEmail"
                  label="Dispatcher Email"
                  name="dispatcherEmail"
                  autoFocus
                  value={formik.values.dispatcherEmail}
                  onChange={formik.handleChange}
                />
                {formik.touched.dispatcherEmail && formik.errors.dispatcherEmail && (
                  <Alert severity="error">{formik.errors.dispatcherEmail}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="driverNames"
                  label="Driver Name(s)"
                  name="driverNames"
                  autoFocus
                  value={formik.values.driverNames}
                  onChange={formik.handleChange}
                />
                {formik.touched.driverNames && formik.errors.driverNames && (
                  <Alert severity="error">{formik.errors.driverNames}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="driverNumbers"
                  label="Driver Number(s)"
                  name="driverNumbers"
                  autoFocus
                  value={formik.values.driverNumbers}
                  onChange={formik.handleChange}
                />
                {formik.touched.driverNumbers && formik.errors.driverNumbers && (
                  <Alert severity="error">{formik.errors.driverNumbers}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="pickupAppointment"
                  label="Is There A Pickup Appointment? Please Enter Appointment Time"
                  required
                  name="pickupAppointment"
                  autoFocus
                  value={formik.values.pickupAppointment}
                  onChange={formik.handleChange}
                />
                {formik.touched.pickupAppointment && formik.errors.pickupAppointment && (
                  <Alert severity="error">{formik.errors.pickupAppointment}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="deliveryAppointment"
                  label="Is There A Delivery Appointment? Please Enter Appointment Time"
                  required
                  name="deliveryAppointment"
                  autoFocus
                  value={formik.values.deliveryAppointment}
                  onChange={formik.handleChange}
                />
                {formik.touched.deliveryAppointment && formik.errors.deliveryAppointment && (
                  <Alert severity="error">{formik.errors.deliveryAppointment}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="pickupTimeZone"
                  label="Pickup Time Zone"
                  required
                  name="pickupTimeZone"
                  autoFocus
                  value={formik.values.pickupTimeZone}
                  onChange={formik.handleChange}
                  select
                >
                  <MenuItem value="EST">EST</MenuItem>
                  <MenuItem value="CST">CST</MenuItem>
                  <MenuItem value="MST">MST</MenuItem>
                  <MenuItem value="PST">PST</MenuItem>
                </TextField>
                {formik.touched.pickupTimeZone && formik.errors.pickupTimeZone && (
                  <Alert severity="error">{formik.errors.pickupTimeZone}</Alert>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="referenceNumber"
                  label="Reference Number"
                  required
                  name="referenceNumber"
                  autoFocus
                  value={formik.values.referenceNumber}
                  onChange={formik.handleChange}
                />
                {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                  <Alert severity="error">{formik.errors.referenceNumber}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="specialInstructions"
                  label="Instructions for Dispatch"
                  name="specialInstructions"
                  multiline
                  rows={4}
                  autoFocus
                  value={formik.values.specialInstructions}
                  onChange={formik.handleChange}
                />
                {formik.touched.specialInstructions && formik.errors.specialInstructions && (
                  <Alert severity="error">{formik.errors.specialInstructions}</Alert>
                )}
              </Grid>
            </Grid>
            {/* { isSubmitted && !socketError ?
              <Box  textAlign="center">
                <Typography sx={{color: '#00deeb', textAlign: 'center'}}>
                  Submitted - {new Date().toLocaleDateString('en-US')}!
                </Typography>
                <Button onClick={closeForm} variant="contained" color="error" sx={{ marginTop: 1 }}>
                  Close Form
                </Button>
              </Box>
              :
              <Box  textAlign="center">
                <Button type="submit" variant="contained" color="primary" >
                Submit
                </Button>
                <Button onClick={closeForm} variant="contained" color="error" sx={{ marginLeft: 2 }}>
                  Close Form
                </Button>
              </Box>
              } */}
            {socketError ?
              <Typography sx={{ color: '#ba000d', textAlign: 'center', marginTop: '10px' }}>
                Error submitting: {socketErrorMessage}
              </Typography>
              : null}
          </form>
        </Container>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {isSubmitted && !socketError ?
          <Box textAlign="center">
            <Typography sx={{ color: '#00deeb', textAlign: 'center' }}>
              Submitted - {new Date().toLocaleDateString('en-US')}!
            </Typography>
            <Button onClick={closeForm} variant="contained" color="error" sx={{ marginTop: 1 }}>
              Close Form
            </Button>
          </Box>
          :
          <Box textAlign="center">
            <Button type="submit" variant="contained" color="primary" onClick={formik.handleSubmit}>
              Submit
            </Button>
            <Button onClick={closeForm} variant="contained" color="error" sx={{ marginLeft: 2 }}>
              Cancel
            </Button>
          </Box>
        }
      </DialogActions>
    </Dialog>
  )


}

export default CarrierSubmissionForm;
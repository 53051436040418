import AddOutlined from "@mui/icons-material/AddOutlined";
import Business from "@mui/icons-material/Business";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import Email from "@mui/icons-material/Email";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import ManageUpdateLeadForm from "components/forms/ManageUpdateLeadForm";
import AddManagerActivityForm from "components/forms/Manager/activities/AddManagerActivityForm";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { DateFormatter } from "components/helpers/DateTimeHelpers";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import { useUpdateLeadMutation } from "state/salesApi";
import ChangeOwnerForm from "./forms/ChangeOwnerForms";
import { useManagerDeleteLeadMutation } from "state/managementApi";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import AddCalendarEventForm from "components/forms/AddCalendarEventForm";
import ComposeEmail from "components/email/ComposeEmail";

const CustomerDetails = ({ customer, onClose, refetch }) => {
  const theme = useTheme();

  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const [updateCustomerOpen, setUpdateCustomerOpen] = useState(false);
  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [reassignOpen, setReassignOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [updateLead, /* { isLoading: isUpdating } */] = useUpdateLeadMutation();
  const [deleteLead, /* { isLoading: isDeleting } */] = useManagerDeleteLeadMutation();

  // Togglers
  const toggleEmailOpen = () => setEmailFormOpen((prev) => !prev);
  const toggleEditLead = () => setUpdateCustomerOpen((prev) => !prev);
  const toggleAddActivity = () => setAddActivityOpen((prev) => !prev);
  const toggleAddEvent = () => setAddEventOpen((prev) => !prev);
  const toggleReassign = () => setReassignOpen((prev) => !prev);
  const toggleDelete = () => setDeleteOpen((prev) => !prev);

  // Handlers
  const handleDNA = async () => {
    const updatedValues = {
      notes: customer.DNA.notes,
      status: !customer.DNA.status,
    };

    try {
      await updateLead({
        id: customer._id,
        leadUpdates: { DNA: updatedValues },
      }).then(() => {
        refetch();
      });
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleCallTomorrow = async () => {
    const updatedValues = {
      callTomorrow: !customer.callTomorrow
    };

    try {
      await updateLead({ id: customer._id, leadUpdates: updatedValues })
        .then(() => {
          refetch();
        });
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLead(customer._id)
        .then(() => {
          enqueueSnackbar(`${customer.name} Deleted`);
          onClose();
          refetch();
        })
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="xl">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Close sx={hoverStyle} onClick={onClose} />
          </div>
          <Divider sx={{ mb: "15px" }} />
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.secondary,
                borderRadius: "8px",
                mb: "20px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.alt,
                      m: "15px 10px",
                      height: "2.9rem",
                      width: "2.9rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <Business sx={{ fontSize: "35px" }} />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h3.fontFamily,
                        fontSize: theme.typography.h3.fontSize,
                      }}
                    >
                      {customer.name || "No name provided"}
                    </Typography>
                    {customer.DNA?.status && customer.DNA.status === "true" && (
                      <Typography>DO NOT ANNOY</Typography>
                    )}
                  </Box>
                </div>
                <div>
                  <Box>
                    {customer?.contactEmail ? (
                      <Button
                        onClick={toggleEmailOpen}
                        color="primary"
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          borderRadius: "2",
                          mr: "15px",
                        }}
                      >
                        <Email style={{ marginRight: "10px" }} />
                        Email
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "none",
                          borderRadius: "2",
                          mr: "15px",
                        }}
                      >
                        No email found
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        boxShadow: "none",
                        border: "1px solid black",
                        borderRight: ".05px solid black",
                        pl: "8px",
                      }}
                      onClick={toggleEditLead}
                    >
                      <Edit sx={{ mr: "5px", fontSize: "15px" }} />
                      Edit
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "0px",
                        boxShadow: "none",
                        border: "1px solid black",
                        borderLeft: "0px solid black",
                        borderRight: ".05px solid black",
                        pl: "8px",
                      }}
                      onClick={toggleAddActivity}
                    >
                      <AddOutlined sx={{ mr: "5px" }} />
                      Activity
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "0px",
                        boxShadow: "none",
                        border: "1px solid black",
                        borderLeft: "0px solid black",
                        borderRight: ".05px solid black",
                        pl: "8px",
                      }}
                      onClick={toggleAddEvent}
                    >
                      <AddOutlined sx={{ mr: "5px" }} />
                      Event
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "0px",
                        boxShadow: "none",
                        border: "1px solid black",
                        borderLeft: "0px solid black",
                        borderRight: ".05px solid black",
                      }}
                      onClick={toggleReassign}
                    >
                      Change Owner
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        boxShadow: "none",
                        border: "1px solid black",
                        borderLeft: "0px solid black",
                        borderRight: ".05px solid black",
                        mr: "10px",
                      }}
                      onClick={toggleDelete}
                    >
                      Delete
                    </Button>
                  </Box>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  mt: "10px",
                }}
              >
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Box sx={{ flexBasis: "25%", wordWrap: "break-word" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h7.fontSize,
                        ml: "5px",
                        mr: "70px",
                      }}
                    >
                      State
                    </Typography>
                    <Divider sx={{ width: "5px" }} />
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h6.fontSize,
                        ml: "5px",
                      }}
                    >
                      {customer.state || ""}
                    </Typography>
                  </Box>
                  <Box sx={{ flexBasis: "25%", wordWrap: "break-word" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h7.fontSize,
                        mr: "70px",
                      }}
                    >
                      Commodity
                    </Typography>
                    <Divider sx={{ width: "50px" }} />
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h6.fontSize,
                      }}
                    >
                      {customer.commodity || ""}
                    </Typography>
                  </Box>
                  <Box sx={{ flexBasis: "25%", wordWrap: "break-word" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h7.fontSize,
                        mr: "70px",
                      }}
                    >
                      Status
                    </Typography>
                    <Divider sx={{ width: "50px" }} />
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h6.fontSize,
                        wordWrap: "break-word",
                        width: "70%",
                      }}
                    >
                      {customer.status
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </Typography>
                  </Box>
                  <Box sx={{ flexBasis: "25%", wordWrap: "break-word" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h7.fontSize,
                        mr: "70px",
                      }}
                    >
                      DSLA
                    </Typography>
                    <Divider sx={{ width: "50px" }} />
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h6.fontSize,
                      }}
                    >
                      {calculateDSLA(customer.recentActivity?.date) === 0
                        ? "Today"
                        : calculateDSLA(customer.recentActivity?.date) > 0
                          ? calculateDSLA(customer.recentActivity?.date)
                          : "Not Contacted"}
                    </Typography>
                  </Box>
                  <Box sx={{ flexBasis: "35%", wordWrap: "break-word" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h7.fontSize,
                        mr: "70px",
                      }}
                    >
                      Assigned To
                    </Typography>
                    <Divider sx={{ width: "50px" }} />
                    <Typography
                      sx={{
                        fontFamily: theme.typography.h6.fontFamily,
                        fontSize: theme.typography.h6.fontSize,
                      }}
                    >
                      {customer.assignedUsers.length
                        ? customer.assignedUsers.length === 1
                          ? customer.assignedUsers[0].name
                          : "Multiple"
                        : customer.status === "pool"
                          ? "Pool"
                          : "Unassigned"}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', }}>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          checked={customer.callTomorrow}
                          onChange={handleCallTomorrow}
                        />
                      }
                      label="Call Tomorrow"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customer.DNA.status}
                          onChange={handleDNA}
                        />
                      }
                      label="Do Not Annoy"
                    />
                  </FormGroup>
                </Box>
              </Box>
            </Box>
            <div sx={{ width: "100%" }}>
              <Grid container direction="row" justifyContent="space-around">
                <Grid item xs={3}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb="5px">
                      Client Information
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Contact Name
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {!customer.contactName &&
                                  !customer.contactLastName
                                  ? ""
                                  : `${customer.contactName || ''} ${customer.contactLastName || ''}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Main Phone
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {customer.contactNumber || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">Email</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {customer.contactEmail || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Secondary Email
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {customer?.secondaryEmail || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      mt="30px"
                      mb="5px"
                    >
                      Business Information
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Company Name
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>{customer.name || ""}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Main Business Type
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {customer.commodity || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">Website</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>{customer.website || ""}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Business Address
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              <Typography>
                                {customer.city || ""} {customer.state || ""}{" "}
                                {customer.country || ""}{" "}
                                {customer.zipCode || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb="5px">
                      Lead Information
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">Title</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.title || ""}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Next Step
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.nextStep || ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      mt="30px"
                      mb="5px"
                    >
                      Financial Information
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">AP Name</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.APName || ""}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                AP Email
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.APEmail || ""}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                AP Number
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.APNumber || ""}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Credit Limit
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {customer.creditLimit || ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb="5px">
                      Activity Information
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableBody>
                          <TableRow sx={{ border: '1px solid grey', }}>
                            <TableCell>
                              <Typography fontWeight="bold">DSLA</Typography>
                            </TableCell>
                            <TableCell sx={{ border: '1px solid grey', }}>
                              {calculateDSLA(customer.DSLA) === 0
                                ? "Today"
                                : calculateDSLA(customer.DSLA) > 0
                                  ? calculateDSLA(customer.DSLA)
                                  : "No Activity"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">DSLE</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {calculateDSLA(customer.DSLE) === 0
                                ? "Today"
                                : calculateDSLA(customer.DSLE) > 0
                                  ? calculateDSLA(customer.DSLE)
                                  : "Not Emailed"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">DSLC</Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {calculateDSLA(customer.DSLC) === 0
                                ? "Today"
                                : calculateDSLA(customer.DSLC) > 0
                                  ? calculateDSLA(customer.DSLC)
                                  : "Not Called"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Date Setup
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {DateFormatter(customer.dateSetup || "")}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                First Load Moved
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {DateFormatter(customer.firstLoadMoved || "")}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Last Load Moved
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {DateFormatter(customer.lastLoadMoved || "")}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ border: "1px solid grey" }}>
                            <TableCell>
                              <Typography fontWeight="bold">
                                Transfer Date
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ border: "1px solid grey" }}>
                              {DateFormatter(customer.transferDate || "")}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                mt="35px"
              >
                <Grid item xs={5}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb="5px">
                      Broker Notes
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      value={customer.brokerNotes}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" mb="5px">
                      CS Notes
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      value={customer.csNotes}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </DialogContent>
      </Dialog>
      {emailFormOpen && (
        <ComposeEmail 
          handleCloseEmailForm={toggleEmailOpen}
          arrayOfEmails={[customer.contactEmail]}
          arrayOfLeadIds={[customer._id]}
          isManager={true}
        />
      )}
      {updateCustomerOpen && (
        <ManageUpdateLeadForm
          mode="update"
          leadToUpdate={customer}
          open={updateCustomerOpen}
          closeForm={toggleEditLead}
          isManager
          updateRefetch={refetch}
        />
      )}
      {addActivityOpen && (
        <AddManagerActivityForm
          customerIds={[customer._id]}
          onClose={toggleAddActivity}
          refetch={refetch}
        />
      )}
      {addEventOpen && <AddCalendarEventForm handleCloseEventForm={toggleAddEvent} leadName={customer.name}/>}
      {reassignOpen && (
        <ChangeOwnerForm
          lead={customer}
          onClose={toggleReassign}
        />
      )}
      <ConfirmationDialog
        open={deleteOpen}
        onClose={toggleDelete}
        onConfirm={handleDelete}
        title={`Confirm Deletion`}
        message={`Are you sure you want to delete ${customer.name}?`}
      />
    </>
  );
};

export default CustomerDetails;

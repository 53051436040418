// import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useGetLeaderboardDataQuery } from "state/api";
import CircularProgress from "@mui/material/CircularProgress";
// import IconButton from "@mui/material/IconButton";
// import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

const Header = () => {
    const cellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell rowSpan={2} /> */}
                <TableCell rowSpan={2} align="center">Broker</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>Margin</TableCell>
                <TableCell colSpan={2} align="center">New Calls</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>FU Calls</TableCell>
                <TableCell colSpan={2} align="center">Packets</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>FU Emails</TableCell>
                <TableCell colSpan={2} align="center">List Emails</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>Loads</TableCell>
                <TableCell colSpan={2} align="center">Setups</TableCell>
                {/* <TableCell colSpan={2} align="center">Weekly Totals</TableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                {/* <TableCell style={cellStyles}>Actual</TableCell> */}
                {/* <TableCell>Quota</TableCell> */}
            </TableRow>
        </TableHead>
    )
};

const Row = ({ agent }) => {
    // const [isOpen, setIsOpen] = useState();

    const oddCellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
        borderBottom: '0.5px solid gray',
    };

    const evenCellStyles = {
        borderBottom: '0.5px solid gray',
    };

    return (
        <>
            <TableRow
                sx={{
                    transition: 'background-color 0.1s',
                    '&:hover': {
                        backgroundColor: '#4F4E4E'
                    }
                }}
            >
                {/* <TableCell>
                    <IconButton
                        aria-label="expand table"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell> */}
                <TableCell /* component="th" scope="row" */ style={evenCellStyles}>
                    {agent.name}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.margin >= agent.marginGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    ${agent.margin}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    ${agent.marginGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyNewCalls >= agent.newCallGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyNewCalls}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.newCallGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyCalls >= agent.callGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyCalls}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.callGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.packets >= agent.packetGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.packets}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.packetGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyEmails >= agent.emailGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyEmails}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.emailGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyListEmails >= agent.dailyListEmailGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyListEmails}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.dailyListEmailGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.loadsBooked >= agent.loadsBookedGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.loadsBooked}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.loadsBookedGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.setups >= agent.setupGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.setups}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.setupGoals}
                </TableCell>
            </TableRow>
        </>
    )
};

// TODO: Double check week quota logic and leaderboard pulling
const QuotaSheet = ({ agents }) => {
    const [quotaRange, setQuotaRange] = useState(0);
    const [weeklyStatsSkip, setWeeklyStatsSkip] = useState(true);

    const {
        data: weeklyStats = [],
        error: statsError,
        isLoading: statsLoading,
        refetch: refetchStats,
        status: statsStatus,
        isSucces: statsSuccess
    } = useGetLeaderboardDataQuery({ limit: 100 }, { skip: weeklyStatsSkip });

    const filteredStats = useMemo(() => {
        return [...agents].sort((a, b) => {
            const valueA = a.name || "";
            const valueB = b.name || "";
            return valueA.localeCompare(valueB);
        }).filter((agent) => agent?.showStats !== false);
    }, [agents]); // THIS MIGHT NOT BE DOING ANYTHING, CHECK IF ALREADY FILTERED IN PROP

    const sortedWeeklyStats = weeklyStats.length ? weeklyStats.filter((agentStats) => agents.find((agent) => agent.name === agentStats.name && agent.showStats))
        .sort((a, b) => {
            const valueA = a.name || "";
            const valueB = b.name || "";
            return valueA.localeCompare(valueB);
        }) : {};

    useEffect(() => {
        if (statsSuccess && statsStatus === "fulfilled") {
            setWeeklyStatsSkip(true);
        }
    }, [weeklyStats, statsSuccess, statsStatus]);

    useEffect(() => {
        if (quotaRange === 1 && weeklyStatsSkip) {
            setWeeklyStatsSkip(false);
        }
    }, [weeklyStatsSkip, quotaRange])

    /* return (
        <Box sx={{ m: '15px 0px' }}>
            <Box sx={{ width: '5%', ml: 5 }}>
                <Slider
                    shiftStep={1}
                    step={1}
                    min={0}
                    max={1}
                    value={quotaRange}
                    onChange={(e) => setQuotaRange(e.target.value)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                        variant="body2"
                        onClick={() => setQuotaRange(0)}
                        sx={{ cursor: 'pointer' }}
                    >
                        Day
                    </Typography>
                    <Typography
                        variant="body2"
                        onClick={() => setQuotaRange(1)}
                        sx={{ cursor: 'pointer' }}
                    >
                        Week
                    </Typography>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{ mt: '1rem', overflow: 'auto', maxHeight: 570 }}>
                <Table aria-label="quota table" size="small" stickyHeader>
                    <Header />
                    <TableBody>
                        {quotaRange === 0 ?
                            filteredStats.map((agent, index) => (
                                <Row key={index} agent={agent} />
                            ))
                            :
                            statsLoading ?
                                <CircularProgress />
                                :
                                (!weeklyStats || !weeklyStats.length) || !sortedWeeklyStats.length ?
                                    ""
                                    :
                                    sortedWeeklyStats.map((agentStats, index) => {
                                        const matchedAgent = agents.find((agent) => (agent.name === agentStats.name && agent.userName));
                                        console.log(matchedAgent)

                                        const agent = {
                                            name: matchedAgent.name || "",
                                            margin: agentStats.margin,
                                            marginGoals: (matchedAgent.marginGoals * 5),
                                            dailyNewCalls: agentStats.dailyNewCalls,
                                            newCallGoals: (matchedAgent.newCallGoals * 5),
                                            dailyCalls: agentStats.dailyCalls,
                                            callGoals: (matchedAgent.callGoals * 5),
                                            packets: agentStats.packets,
                                            packetGoals: (matchedAgent.packetGoals * 5),
                                            dailyEmails: agentStats.dailyEmails,
                                            emailGoals: (matchedAgent.emailGoals * 5),
                                            dailyListEmails: agentStats.dailyListEmails,
                                            dailyListEmailGoals: (matchedAgent.dailyListEmailGoals * 5),
                                            loadsBooked: agentStats.loadsBooked,
                                            loadsBookedGoals: (matchedAgent.loadsBookedGoals * 5),
                                            setups: agentStats.setups,
                                            setupGoals: (matchedAgent.setupGoals * 5),
                                        };
        
                                        return (
                                            <Row key={index} agent={agent} />
                                        )
                                        return (
                                            <div key={index}>test</div>
                                        )
                                    })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    ) */

    return (
        <Box sx={{ m: '15px 0px ' }}>
            <Box sx={{ width: '5%', ml: 5 }}>
                <Slider
                    shiftStep={1}
                    step={1}
                    min={0}
                    max={1}
                    value={quotaRange}
                    onChange={(e) => setQuotaRange(e.target.value)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                        variant="body2"
                        onClick={() => setQuotaRange(0)}
                        sx={{ cursor: 'pointer' }}
                    >
                        Day
                    </Typography>
                    <Typography
                        variant="body2"
                        onClick={() => setQuotaRange(1)}
                        sx={{ cursor: 'pointer ' }}
                    >
                        Week
                    </Typography>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{ mt: '1rem', overflow: 'auto', maxHeight: 570 }}>
                <Table aria-label="quota table" size="small" stickyHeader>
                    <Header />
                    <TableBody>
                        {quotaRange === 0 ? // If quota set for today
                            filteredStats.map((agent, index) => (
                                <Row key={index} agent={agent} />
                            ))
                            : statsLoading ? ( // If stats api call loading
                                <TableRow>
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : statsError ? ( // If error fetching stats
                                <TableRow>
                                    <TableCell>
                                        There was an issue retrieving statistics
                                    </TableCell>
                                </TableRow>
                            ) : !weeklyStats.length ? ( // If no data from api call
                                <TableRow>
                                    <TableCell>
                                        No data yet
                                    </TableCell>
                                </TableRow>
                            ) : (
                                sortedWeeklyStats.map((agentStats, index) => {
                                    const matchedAgent = filteredStats.find((agent) => (agent.name === agentStats.name));

                                    const agent = {
                                        name: matchedAgent.name || "",
                                        margin: agentStats.totalMargin || 0,
                                        marginGoals: (matchedAgent.marginGoals * 5),
                                        dailyNewCalls: agentStats.totalNewCalls || 0,
                                        newCallGoals: (matchedAgent.newCallGoals * 5),
                                        dailyCalls: agentStats.totalCalls || 0,
                                        callGoals: (matchedAgent.callGoals * 5),
                                        packets: agentStats.totalPackets || 0,
                                        packetGoals: (matchedAgent.packetGoals * 5),
                                        dailyEmails: agentStats.totalEmails || 0,
                                        emailGoals: (matchedAgent.emailGoals * 5),
                                        dailyListEmails: agentStats.totalListEmails || 0,
                                        dailyListEmailGoals: (matchedAgent.dailyListEmailGoals * 5),
                                        loadsBooked: agentStats.totalLoadsBooked || 0,
                                        loadsBookedGoals: (matchedAgent.loadsBookedGoals * 5),
                                        setups: agentStats.totalSetups || 0,
                                        setupGoals: (matchedAgent.setupGoals * 5),
                                    };

                                    return (
                                        <Row key={index} agent={agent} />
                                    );
                                })
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default QuotaSheet;
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Delete from "@mui/icons-material/Delete";
import MarkChatRead from "@mui/icons-material/MarkChatRead";
import MarkChatUnread from "@mui/icons-material/MarkChatUnread";
import Pending from "@mui/icons-material/Pending";
import { useState } from "react";
import { highlightStyle } from "components/helpers/MediaQueries";
import { enqueueSnackbar } from "notistack";
import CustomerQuoteSubmissionForm from "components/forms/CustomerQuoteSubmissionForm";
import { useDeleteNotificationForUserMutation, useUpdateNotificationForUserMutation } from "state/api";

const NotificationsNav = ({ notifications, user, onNotificationClick, onClose }) => {
    const theme = useTheme();
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const [notificationSettingsOpen, setNotificationSettingsOpen] = useState(false);
    const [settingsNotification, setSettingsNotification] = useState(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [newQuoteOpen, setNewQuoteOpen] = useState(false);
    const [newQuoteId, setNewQuoteId] = useState('');

    let unreadCount;

    // Queries & Mutations
    const [updateNotification] = useUpdateNotificationForUserMutation();
    const [deleteNotification] = useDeleteNotificationForUserMutation();

    // Togglers
    const toggleFilter = () => setShowUnreadOnly(prev => !prev);
    const toggleNewQuote = () => setNewQuoteOpen(prev => !prev);

    // Handlers
    const handleSettingsClick = (e, notification) => {
        setSettingsAnchorEl(settingsAnchorEl ? null : e.currentTarget);
        setSettingsNotification(settingsNotification ? null : notification);
        setNotificationSettingsOpen(!notificationSettingsOpen);
    };
    
    const handleMarkAsRead = async (notification) => {
        const updates = {
            userId: user._id,
            isRead: !notification.isRead // Mark as read or unread
        };

        try {
            await updateNotification({ id: notification._id, data: updates })
                .then(() => {
                    handleSettingsClick();
                })
        } catch (error) {
            console.error('Error updating notification:', error);
            enqueueSnackbar('There was an issue updating your notification');            
        }
    };
    
    const handleDeleteNotification = async (notificationId) => {
        try {
            await deleteNotification({ id: notificationId, userId: user._id })
                .then(() => {
                    handleSettingsClick();
                })
        } catch (error) {
            console.error('Error deleting notification:', error);
            enqueueSnackbar('There was an issue deleting your notification');
        }
    };

    const handleQuoteSubmission = (quoteId) => {
        toggleNewQuote();
        // const submittedQuoteNotification = notifications.find((notification) => notification.link === quoteId);
        // Maybe handle mark notification as read on submit
    };


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h5">
                    Notification Center
                </Typography>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Switch 
                                color="secondary"
                                onChange={toggleFilter}
                                checked={showUnreadOnly}
                            />
                        }
                        label="Unread Only"
                    />
                </FormGroup>
            </Box>
            <Stack
                sx={{
                    maxHeight: '400px',
                    width: 'min(80ch, 100ch)',
                    padding: '25px',
                    borderRadius: '8px',
                    overflowY: 'auto',
                }}
                direction='column-reverse'
                spacing={2}
            >
                {(!notifications.length || (unreadCount === 0 && showUnreadOnly)) && (
                    <Typography variant="h4">
                        All caught up!
                        {/* <span role="img" aria-label="party popper">
                            🎉
                        </span> */}
                    </Typography>
                )}
                {(showUnreadOnly ? notifications.filter((n) => !n.isRead) : notifications).map((notification, index) => {
                    let severity;

                    switch (notification.status) {
                        case "Critical":
                            severity = "error";
                            break;

                        case "Action Required":
                            severity = "warning";
                            break;
                            
                        case "Informational":
                            severity = "info";
                            break;
                            
                        case "Reminder":
                            severity = "success";
                            break;
                    
                        default:
                            severity = "info";
                            break;
                    }

                    return (
                        <Alert
                            key={index}
                            severity={severity}
                            action={
                                <IconButton 
                                    onClick={(e) => {
                                        handleSettingsClick(e, notification);
                                    }}
                                >
                                    <Pending />
                                </IconButton>
                            }
                        >
                            {/* NOTIFICATION SETTINGS POPOVER */}
                            {(settingsNotification && notificationSettingsOpen) && settingsNotification._id === notification._id && (
                                <Popper
                                    open
                                    anchorEl={settingsAnchorEl}
                                    placement="bottom"
                                    transition
                                    modifiers={[
                                        {
                                            name: 'preventOverflow',
                                            enabled: true,
                                            boundariesElement: 'window',
                                        }
                                    ]}
                                    sx={{ zIndex: 2000 }}
                                >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={150}>
                                            <Paper sx={{ borderRadius: '50px', }}>
                                                <ClickAwayListener onClickAway={handleSettingsClick}>
                                                    <Paper sx={{ backgroundColor: theme.palette.background.paper, maxWidth: 1000, borderRadius: '10px', }}>
                                                        <Box sx={{ padding: theme.spacing(2), }}>
                                                            <Stack
                                                                sx={{
                                                                    height: '150px',
                                                                    width: 'min(25ch, 35ch)',
                                                                    padding: '5px',
                                                                }}
                                                                spacing={2}
                                                            >
                                                                {notification.isRead ? (
                                                                    <Paper 
                                                                        sx={highlightStyle} 
                                                                        style={{ 
                                                                            display: 'flex', 
                                                                            justifyContent: 'space-between', 
                                                                            alignItems: 'center', 
                                                                            padding: 15,
                                                                        }}
                                                                        onClick={() => handleMarkAsRead(notification)}
                                                                    >
                                                                        <MarkChatUnread />
                                                                        <Typography>Mark as unread</Typography>
                                                                    </Paper>
                                                                ) : (
                                                                    <Paper 
                                                                        sx={highlightStyle} 
                                                                        style={{ 
                                                                            display: 'flex', 
                                                                            justifyContent: 'space-between', 
                                                                            alignItems: 'center', 
                                                                            padding: 15,
                                                                        }}
                                                                        onClick={() => handleMarkAsRead(notification)}
                                                                    >
                                                                        <MarkChatRead />
                                                                        <Typography>Mark as read</Typography>
                                                                    </Paper>
                                                                )}
                                                                <Paper 
                                                                    sx={highlightStyle} 
                                                                    style={{ 
                                                                        display: 'flex', 
                                                                        justifyContent: 'space-between', 
                                                                        alignItems: 'center', 
                                                                        padding: 15 
                                                                    }}
                                                                    onClick={() => handleDeleteNotification(notification._id)}
                                                                >
                                                                    <Delete />
                                                                    <Typography>Delete notification</Typography>
                                                                </Paper>
                                                            </Stack>
                                                        </Box>
                                                    </Paper>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            )}
                            <Box 
                                // sx={{ cursor: 'default', }}
                                sx={{ cursor: notification.title === "New Quote" ? 'pointer' : 'default' }}
                                onClick={() => {
                                    if (notification.title === "New Quote") {
                                        setNewQuoteId(notification.link);
                                        toggleNewQuote();
                                    }
                                    /* if (!notification.isRead) {
                                        // Handle new quote stuff only if customer quote notification
                                        // Mark it as read
                                        // setNewQuoteId(notification.link);
                                        // toggleNewQuote();
                                    }else {      Look into way to tell if quote already submitted
                                        enqueueSnackbar("Quote already submitted for customer");
                                    } */
                                }}
                            >
                                <Typography variant="h4" sx={{ mb: '15px', fontWeight: notification.isRead ? 'normal' : 'bold' }}>{notification.title}</Typography>
                                <Typography variant="h5" sx={{ fontWeight: notification.isRead ? 'normal' : 'bold' }}>{notification.description}</Typography>
                            </Box>
                        </Alert>
                    )
                })}
            </Stack>
            {newQuoteOpen && newQuoteId && 
                <CustomerQuoteSubmissionForm 
                    open={newQuoteOpen}
                    closeForm={toggleNewQuote}
                    quoteId={newQuoteId}
                    onSubmit={handleQuoteSubmission}
                />
            }
        </>
    )

    /* return (
        <Box>
            <Tooltip title={isOpen ? "" : "Notifications"} placement="bottom">
                <IconButton size="large" onClick={toggleNotificationsNav}>
                    <Badge badgeContent={notifications.length} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popper
                placement="bottom-start"
                open={isOpen}
                onClose={onClose}
                anchorEl={anchorEl}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: isOpen,
                        options: {
                            element: arrowRef,
                        }
                    }
                ]}
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                        <Paper elevation={6}>
                            <Box
                                sx={{
                                    padding: "8px",
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="h5">
                                    Notification Center
                                </Typography>
                                <FormGroup>
                                    <FormControlLabel 
                                        control={
                                            <Switch 
                                                color="secondary"
                                                onChange={toggleFilter}
                                                checked={showUnreadOnly}
                                            />
                                        }
                                        label="Unread Only"
                                    />
                                </FormGroup>
                            </Box>
                            <Stack
                                sx={{
                                    height: '400px',
                                    width: 'min(60ch, 100ch)',
                                    padding: '12px',
                                    borderRadius: '8px',
                                    overflowY: 'auto'
                                }}
                                spacing={2}
                            >
                                {(!notifications.length || (unreadCount === 0 && showUnreadOnly)) && (
                                    <Typography variant="h4">
                                        All caught up!
                                        <span role="img" aria-label="party popper">
                                            🎉
                                        </span>
                                    </Typography>
                                )}
                                {(showUnreadOnly ? notifications.filter((n) => !n.read) : notifications).map((notification, index) => {
                                    let severity;

                                    switch (notification.status) {
                                        case "Critical":
                                            severity = "error";
                                            break;

                                        case "Action Required":
                                            severity = "warning";
                                            break;
                                            
                                        case "Informational":
                                            severity = "info";
                                            break;
                                            
                                        case "Reminder":
                                            severity = "success";
                                            break;
                                    
                                        default:
                                            severity = "info";
                                            break;
                                    }

                                    return (
                                        <Alert
                                            key={index}
                                            severity={severity}
                                            action={
                                                notification.isRead ? (
                                                    <Tooltip title="Mark as read">
                                                        <IconButton
                                                            onClick={() => handleDeleteNotification(notification._id)}
                                                            aria-label="delete notification"
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <IconButton
                                                        onClick={() => handleMarkAsRead(notification._id)}
                                                    >
                                                        <MarkChatRead />
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            <Typography variant="h3">{notification.title}</Typography>
                                            <Typography variant="h5">{notification.description}</Typography>
                                        </Alert>
                                    )
                                })}
                            </Stack>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    ) */
}

export default NotificationsNav;
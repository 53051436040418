import Archive from "@mui/icons-material/Archive";
import Assignment from "@mui/icons-material/Assignment";
import Book from "@mui/icons-material/Book";
import Check from "@mui/icons-material/Check";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditCalendar from "@mui/icons-material/EditCalendar";
import KeyboardReturn from "@mui/icons-material/KeyboardReturn";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import AddOperationsTeamMemberMenu from "components/forms/Manager/operations/AddOperationsTeamMemberMenu";
import handleUpdateDifferences from "components/helpers/UpdateFormDifferences";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCreateQuoteMutation, useDeleteQuoteMutation, useUpdateQuoteMutation } from "state/operationsApi";
import AssignQuoteMenu from "./Menus/AssignQuoteMenu";
import EditQuoteForm from "components/quotes/forms/EditQuoteForm";
import EditColorsMenu from "./Menus/EditColorsMenu";
import QuoteCarrierSubmissionForm from "components/quotes/forms/QuoteCarrierSubmissionForm";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { handleDatetimeDifference } from "components/helpers/DateTimeHelpers";
import { useSendQuoteToCustomerPortalMutation } from "state/salesApi";

const OperationsBoard = ({ quotes, refetchQuotes, operationsTeamMembers, assignedTo, setAssignedTo }) => {
    const theme = useTheme();
    const userData = useSelector((state) => state.user);
    const userRole = userData.role;

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const [unassignedQuotes, setUnassignedQuotes] = useState([]);
    const [customerQuotes, setCustomerQuotes] = useState([]);

    const [filterOpen, setFilterOpen] = useState(false);
    // const [activeFiters, setActiveFiters] = useState([]);
    const [editMembersOpen, setEditMembersOpen] = useState(false);
    const [isAssigning, setIsAssigning] = useState(false);
    const [quoteToAssign, setQuoteToAssign] = useState(null);
    const [isBooking, setIsBooking] = useState(false);
    const [quoteToBook, setQuoteToBook] = useState(null);
    const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [quoteToEdit, setQuoteToEdit] = useState(null);
    const [quoteToArchive, setQuoteToArchive] = useState(null);
    const [quoteToDelete, setQuoteToDelete] = useState(null);

    const [isEditingColor, setIsEditingColor] = useState(false);

    const [expandedCards, setExpandedCards] = useState({});

    const [createQuote] = useCreateQuoteMutation();
    const [updateQuote] = useUpdateQuoteMutation();
    const [sendQuoteToCustomerPortal] = useSendQuoteToCustomerPortalMutation();
    const [deleteQuote] = useDeleteQuoteMutation();

    // useEffect for filtering out unassigned quotes
    useEffect(() => {
        if (quotes) {
            const unassigned = quotes.filter((quote) => (!quote?.assignedTo?.name && quote.emailSubject !== "Customer Portal Quote Request") && (quote.status !== "quoted" && quote.status !== "brokerBooking"));
            const customers = quotes.filter((quote) => (!quote?.assignedTo?.name && quote.emailSubject === "Customer Portal Quote Request") && (quote.status !== "quoted" && quote.status !== "brokerBooking"));
            setUnassignedQuotes(unassigned);
            setCustomerQuotes(customers);
        };

    }, [quotes, refetchQuotes]);

    // useEffect for handling onMount and onUnmount
    useEffect(() => {
        //  Setup time interval for quote creation time updating
        const quoteTimeInterval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 60000);

        return () => clearInterval(quoteTimeInterval); // Clear quote time interval
    }, []);

    // Togglers
    const toggleFilterDialog = () => setFilterOpen(prev => !prev);
    const toggleEditMembers = () => setEditMembersOpen(prev => !prev);
    const toggleEditingColors = () => setIsEditingColor(prev => !prev);
    const toggleIsAssigning = () => setIsAssigning(prev => !prev);
    const toggleIsEditing = () => setIsEditing(prev => !prev);
    const toggleIsBooking = () => setIsBooking(prev => !prev);
    const toggleArchiveConfirm = () => setArchiveConfirmOpen(prev => !prev);
    const toggleDeleteConfirm = () => setDeleteConfirmOpen(prev => !prev);


    // Handlers
    // const handleFilterChange = (agentName) => {
    //     if (assignedTo === agentName) {
    //         setAssignedTo(undefined);
    //     } else {
    //         setAssignedTo(agentName);
    //     }
    // };

    const handleToggleCardExpansion = (cardId) => {
        setExpandedCards((prev) => ({
            ...prev,
            [cardId]: !prev[cardId],
        }));
    };

    const handleAssignCard = async (agent) => {
        const quoteUpdate = {
            assignedTo: {
                name: agent.name,
                email: agent.email,
            },
        };

        try {
            await updateQuote({ id: quoteToAssign._id, updates: quoteUpdate })
                .then(() => {
                    enqueueSnackbar(`Quote assigned to ${agent.name}`);
                    toggleIsAssigning();
                })
        } catch (error) {
            console.error("Error assigning quote:", error);
        }
    };

    const handleCopyCard = async (card) => {
        const quoteCopy = {
            emailSubject: card.emailSubject,
            origin: card.origin,
            destination: card.destination,
            equipType: card.equipType,
            loadType: card.loadType,
            truckPrice: card.truckPrice,
            customerQuote: card.customerQuote,
            dispatcherName: card.dispatcherName,
            dispatcherPhone: card.dispatcherPhone,
            margin: card.margin,
            driverName: card.driverName,
            driverPhone: card.driverPhone,
            pickupAndDeliveryETA: card.pickupAndDeliveryETA,
            shipmentDate: card.shipmentDate,
            truckInHand: card.truckInHand,
            carrierInfo: card.carrierInfo,
            notes: card.notes,
            quoteStatus: card.quoteStatus,
            status: card.status
        };

        try {
            await createQuote(quoteCopy)
                .then(() => {
                    enqueueSnackbar("Quote duplicated");
                })
        } catch (error) {
            console.error('Error duplicating card:', error);
        }
    };

    const handleCalendarEvent = (quote) => {
        window.alert("In Progress");
    };

    const handleUpdateCard = async (card) => {
        const quoteUpdate = await handleUpdateDifferences(quoteToEdit, card);

        try {
            await updateQuote({ id: quoteToEdit._id, updates: quoteUpdate })
                .then(() => {
                    enqueueSnackbar(`Quote Updated`);
                    toggleIsEditing();
                })
        } catch (error) {
            console.error("Error updating quote:", error);
        }
    };

    const handleCompleteCard = async (item) => {
        const changes = {
            status: "quoted"
        };

        try {
            await updateQuote({ id: item._id, updates: changes })
                .then(() => {
                    enqueueSnackbar(`Quote Completed`);
                    handleToggleCardExpansion(item._id);
                });
        } catch (error) {
            console.error("Error completing quote:", error);
        }
    };

    const handleCustomerCompleteCard = async (item) => {
        const changes = {
            status: "quoted"
        };
        
        try {
            await updateQuote({ id: item._id, updates: changes })
                .then(() => {
                    enqueueSnackbar(`Quote Completed`);
                    handleToggleCardExpansion(item._id);
                })
            await sendQuoteToCustomerPortal({ quoteId: item._id, status: "quoted", customerPortalUserId: item.customerPortalUserId })

        } catch (error) {
            console.error("Error completing quote:", error);
        }
    };

    const handleBrokerBooking = async (item) => {
        const changes = {
            status: "brokerBooking"
        };

        try {
            await updateQuote({ id: item._id, updates: changes })
                .then(() => {
                    enqueueSnackbar(`Quote Returned to Broker`);
                });
        } catch (error) {
            console.error("Error returning quote:", error);
        }
    };

    const handleArchiveCard = async () => {
        const changes = {
            isArchived: true
        };

        try {
            await updateQuote({ id: quoteToArchive._id, updates: changes })
                .then(() => {
                    enqueueSnackbar(`Quote Archived`);
                    toggleArchiveConfirm();
                    setQuoteToArchive(null);
                });
        } catch (error) {
            console.error("Error archiving card:", error);
        }
    };

    const handleDeleteCard = async () => {
        try {
            await deleteQuote(quoteToDelete._id)
                .then(() => {
                    enqueueSnackbar(`Quote Deleted`);
                    toggleDeleteConfirm();
                    setQuoteToDelete(null);
                });
        } catch (error) {
            console.error("Error deleting quote:", error);
        }
    };

    // TODO: Fix stutter
    const handleDragEnd = async (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) {
            return;
        };
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        };

        const agentName = destination.droppableId.split('-')[0];
        if (agentName !== "Unassigned") {
            const matchedAgent = operationsTeamMembers.find((member) => member.name === agentName);

            const quoteUpdate = {
                assignedTo: {
                    name: matchedAgent.name,
                    email: matchedAgent.email,
                },
            };

            try {
                await updateQuote({ id: draggableId, updates: quoteUpdate })
                    .then(() => {
                        enqueueSnackbar(`Quote assigned to ${matchedAgent.name}`);
                    });
            } catch (error) {
                console.error("Error assigning quote:", error);
            };
        } else {
            const quoteUpdate = {
                assignedTo: {},
            };

            try {
                await updateQuote({ id: draggableId, updates: quoteUpdate })
                    .then(() => {
                        enqueueSnackbar(`Quote unassigned`);
                    });
            } catch (error) {
                console.error("Error assigning quote:", error);
            }
        }
    };


    // HTML for columns
    const column = (quoteCards, columnId, columnName) => {
        return (
            <Paper
                elevation={3}
                sx={{
                    mb: '15px',
                    pb: '5px',
                    backgroundColor: theme.palette.background.secondary,
                }}
            >
                <Typography
                    variant="h6"
                    component="h2"
                    align="center"
                    gutterBottom
                    sx={{ backgroundColor: theme.palette.primary[900] }}
                >
                    {columnName}
                </Typography>
                <Droppable droppableId={columnId}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {quoteCards.map((item, index) => {
                                const isExpanded = expandedCards[item._id]

                                return (
                                    <Draggable
                                        key={item._id}
                                        draggableId={item._id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Paper
                                                    elevation={3}
                                                    sx={{
                                                        backgroundColor: item.status === "quoting"
                                                            ? "#2276C1" : item.status === "quoted"
                                                                ? "#2276C1" : item.status === "booking"
                                                                    ? "#997d3d" : item.status === "booked"
                                                                        ? "#12A135" : item.status === "refused"
                                                                            ? "#A13512" : "#000000"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: '8px',
                                                            marginBottom: '5px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            {(item.status !== "complete" && item.status !== "archived") && (
                                                                <Box>
                                                                    {handleDatetimeDifference(item.createdAt, currentDateTime)}
                                                                </Box>
                                                            )}
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <Box>
                                                                    <Box sx={{ mt: '15px' }}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            component="h3"
                                                                            gutterBottom
                                                                        >
                                                                            {!item.customerPortalUserId ? (
                                                                                <Tooltip title="Open Quote Email" placement="top">
                                                                                    <a
                                                                                        href={item.gmailLink}
                                                                                        target="_blank"
                                                                                        style={{
                                                                                            textDecoration: 'underline',
                                                                                            color: 'inherit'
                                                                                        }}
                                                                                    >
                                                                                        {item.emailSubject}
                                                                                    </a>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                item.emailSubject
                                                                            )}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            gutterBottom
                                                                        >
                                                                            {item.status.replace(/^\w/, (c) => c.toUpperCase())}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            gutterBottom
                                                                        >
                                                                            Status: {item.quoteStatus.replace(/^\w/, (c) => c.toUpperCase())}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            gutterBottom
                                                                        >
                                                                            Shipment Date: {item.shipmentDate || ""}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            expandedCards[item._id] || false
                                                                        }
                                                                        onChange={() => handleToggleCardExpansion(item._id)}
                                                                    />
                                                                </Box>
                                                            </div>
                                                            <Typography sx={{ textAlign: 'center', m: '5px 0px', textDecoration: 'underline', }}>
                                                                Quote Owner: {item.ownerDetails.name}
                                                            </Typography>
                                                        </div>
                                                        {isExpanded && (
                                                            <div>
                                                                <Grid container spacing={3} sx={{ pt: '15px' }}>
                                                                    <Grid item xs={6}>
                                                                        <b>Origin</b>: {item.origin.city || ""}, {item.origin.state || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Destination</b>: {item.destination.city || ""}, {item.destination.state || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Equipment Type</b>: {item.equipType || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Load Type</b>: {item.loadType || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        {/* <b>Truck Price</b>: {`$${parseFloat(item.truckPrice || 0).toFixed(2) || 0}`} */}
                                                                        <b>Truck Price</b>: {item.truckPrice ? `$${parseFloat(item.truckPrice || 0).toFixed(2)}` : ''}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        {/* <b>Customer Quote</b>: {`$${parseFloat(item.customerQuote).toFixed(2)}` || ""} */}
                                                                        <b>Customer Quote</b>: {item.customerQuote ? `$${parseFloat(item.customerQuote || 0).toFixed(2)}` : ''}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Truck in Hand</b>: {item.truckInHand ? "Yes" : "No"}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Pickup and Delivery ETA</b>: {item.pickupAndDeliveryETA || ""}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <b>MC Number</b>: {item.carrierInfo.mcNumber || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Dispatcher Name</b>: {item.dispatcherName || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Dispatcher Phone Number</b>: {item.dispatcherPhone || ""}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <b>Dispatcher Email</b>: {item.carrierInfo.email || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Driver Name</b>: {item.driverName || ""}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <b>Driver Phone Number</b>: {item.driverPhone || ""}
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ mb: '30px' }}>
                                                                        <b>Notes</b>: {item.notes || ""}
                                                                    </Grid>
                                                                </Grid>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-evenly' }}>
                                                                        <Tooltip title="Assign" placement="top">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    setQuoteToAssign(item);
                                                                                    toggleIsAssigning();
                                                                                }}
                                                                                size="small"
                                                                            >
                                                                                <Assignment />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Duplicate" placement="top">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    handleCopyCard(item);
                                                                                }}
                                                                                size="small"
                                                                            >
                                                                                <ContentCopy />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Edit" placement="top">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    setQuoteToEdit(item);
                                                                                    toggleIsEditing();
                                                                                }}
                                                                                size="small"
                                                                            >
                                                                                <Edit />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Add Event" placement="top">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    handleCalendarEvent(item);
                                                                                }}
                                                                                size="small"
                                                                            >
                                                                                <EditCalendar />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {item.status === "quoting" && (
                                                                            <Tooltip title="Quoted" placement="top">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        if (item.customerPortalUserId) {
                                                                                            handleCustomerCompleteCard(item);
                                                                                        } else {
                                                                                            handleCompleteCard(item);
                                                                                        }
                                                                                    }}
                                                                                    size="small"
                                                                                >
                                                                                    <Check />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {item.status === "booking" && (
                                                                            <>
                                                                                <Tooltip title="Return to Broker" placement="top">
                                                                                    <IconButton
                                                                                        onClick={() => handleBrokerBooking(item)}
                                                                                        size="small"
                                                                                    >
                                                                                        <KeyboardReturn />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Booked" placement="top">
                                                                                    <IconButton
                                                                                        onClick={() => {
                                                                                            toggleIsBooking();
                                                                                            setQuoteToBook(item);
                                                                                        }}
                                                                                        size="small"
                                                                                    >
                                                                                        <Book />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </>
                                                                        )}
                                                                        {(item.status === "booked" || item.status === "refused") && (
                                                                            <Tooltip title="Archive" placement="top">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        toggleArchiveConfirm();
                                                                                        setQuoteToArchive(item);
                                                                                    }}
                                                                                    size="small"
                                                                                >
                                                                                    <Archive />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Box>
                                                                    <Box>
                                                                        {(userData.role === "manager" || userData.role === "admin" || userData.role === "superadmin") && (
                                                                            <Tooltip title="Delete" placement="top">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        toggleDeleteConfirm();
                                                                                        setQuoteToDelete(item);
                                                                                    }}
                                                                                    size="small"
                                                                                >
                                                                                    <Delete />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Paper>
        );
    };

    if (!operationsTeamMembers || !quotes) return;

    return (
        <>
            <Grid container spacing={1} sx={{ backgroundColor: theme.palette.background.secondary, padding: 2 }}>
                <Grid item xs={12} md={2}>
                    <Grid item container spacing={8}>
                        {/* <Grid item xs={12} sm={4} md={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={toggleFilterDialog}
                            >
                                Filter
                            </Button>
                        </Grid> */}
                        {(userRole === "manager" || userRole === "superadmin" || userRole === "admin") && (
                            <Grid item xs={12} sm={4} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={toggleEditMembers}
                                >
                                    Edit Members
                                </Button>
                            </Grid>
                        )}
                        {filterOpen && (
                            <Dialog open={filterOpen} onClose={toggleFilterDialog}></Dialog>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ backgroundColor: theme.palette.background.secondary, padding: 2 }}>
                <Grid item xs={12} md={12}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                {column(unassignedQuotes, "Unassigned-column", "Unassigned")}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                {column(customerQuotes, "Customer-column", "Customer Portal")}
                            </Grid>
                            {operationsTeamMembers.map((member) => {
                                const assignedQuotes = quotes.filter((quote) => quote?.assignedTo?.name === member.name && (quote.status !== "quoted" && quote.status !== "brokerBooking"));
                                const memberName = member.name === "KTB Operations" ? "Future Loads" : member.name

                                return (
                                    <Grid item key={member.name} xs={12} sm={6} md={6} lg={4}>
                                        {column(assignedQuotes, `${member.name}-column`, memberName)}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </DragDropContext>
                </Grid>
            </Grid>
            {editMembersOpen &&
                <AddOperationsTeamMemberMenu
                    onClose={toggleEditMembers}
                    currentOpsMembers={operationsTeamMembers.map((current) => {
                        const currentOpsMembers = {
                            _id: current.user._id,
                            name: current.name,
                            email: current.email,
                        };

                        return currentOpsMembers;
                    })}
                />
            }
            {isAssigning &&
                <AssignQuoteMenu
                    quote={quoteToAssign}
                    opsMembers={operationsTeamMembers}
                    onClose={toggleIsAssigning}
                    onSubmit={handleAssignCard}
                />}
            {isEditing &&
                <EditQuoteForm
                    quote={quoteToEdit}
                    onClose={toggleIsEditing}
                    onSubmit={handleUpdateCard}
                />}
            {isEditingColor &&
                <EditColorsMenu
                    opsMembers={operationsTeamMembers}
                    onClose={toggleEditingColors}
                />}
            {isBooking &&
                <QuoteCarrierSubmissionForm
                    quote={quoteToBook}
                    onClose={toggleIsBooking}
                />}
            {archiveConfirmOpen &&
                <ConfirmationDialog
                    open={archiveConfirmOpen}
                    onClose={toggleArchiveConfirm}
                    onConfirm={handleArchiveCard}
                    title="Confirm Archive"
                    message={`Are you sure you want to archive this quote?`}
                />}
            {deleteConfirmOpen &&
                <ConfirmationDialog
                    open={deleteConfirmOpen}
                    onClose={toggleDeleteConfirm}
                    onConfirm={handleDeleteCard}
                    title="Confirm Delete"
                    message={`Are you sure you want to delete this quote?`}
                />}
        </>
    )

}

export default OperationsBoard;